import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { ProductPricingDto, ProductPricing } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getProductPricing(id: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductPricingDto[]>>(`api/v1/uoms/items/${id}`)
            .pipe(map((response) => response.data.map((item) => new ProductPricing(item))));
    }
}
