import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '../../models';
import { ProductPricing } from '../models/product-pricing/product-pricing.model';

export const productPricingActions = createActionGroup({
    source: 'Product Pricing',
    events: {
        'Set Product Pricings': props<{ product: Product }>(),
        'Set Product Pricings Triggers': props<{ id: string; no: string }>(),

        'Get Product Pricings': props<{ id: string; no: string }>(),
        'Get Product Pricings Success': props<{ id: string; no: string; items: ProductPricing[] }>(),
        'Get Product Pricings Error': props<{ id: string; no: string }>(),

        Clear: emptyProps(),
    },
});
