import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '@app/store/models';
import { Lang } from '@app/i18n/lang.type';
import { ProductsValue } from '../models';

export const productsActions = createActionGroup({
    source: 'Products',
    events: {
        'Get Products': props<{ params: ProductsValue; lang: Lang }>(),
        'Get Products Params': props<{ lang: Lang }>(),
        'Get Products Params Success': props<{ items: Product[]; totalItems: number; params: ProductsValue }>(),
        'Get Products Params Error': props<{ params: ProductsValue }>(),
        'Clear Results': emptyProps(),
        Clear: emptyProps(),
    },
});
