import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PriceTotalPipe } from './pipes/total-price.pipe';
import { PriceTotalVatPipe } from './pipes/total-vat-price.pipe';
import { PriceTotalGrossPipe } from './pipes/total-gross-price.pipe';
import { CartItemProcessingPipe } from './pipes/cart-item-processing.pipe';
import { CartProcessingPipe } from './pipes/cart-processing.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [PriceTotalPipe, PriceTotalVatPipe, PriceTotalGrossPipe, CartProcessingPipe, CartItemProcessingPipe],
    exports: [PriceTotalPipe, PriceTotalVatPipe, PriceTotalGrossPipe, CartProcessingPipe, CartItemProcessingPipe],
})
export class CartModule {}
