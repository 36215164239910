import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCT_DISCOUNTS_FEATURE_KEY } from './keys';
import { productDiscountsReducer } from './reducers/product-discounts.reducer';
import { ProductDiscountsEffects } from './effects/product-discounts.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PRODUCT_DISCOUNTS_FEATURE_KEY, productDiscountsReducer),
        EffectsModule.forFeature([ProductDiscountsEffects]),
    ],
})
export class ProductDiscountsStoreModule {}
