import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingCartItem } from '@app/store/shopping-cart';

@Pipe({
    name: 'totalVat',
})
export class PriceTotalVatPipe implements PipeTransform {
    transform(cartItems: ShoppingCartItem[] | null): number | null {
        return (
            cartItems?.reduce((total, { qtyPerUnit, product, qty }) => {
                const value = qtyPerUnit ? product.discountPrice * (product.vatRate / 100) * qty * qtyPerUnit : 0;

                return total + value;
            }, 0) ?? null
        );
    }
}
