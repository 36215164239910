import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCT_PRICING_FEATURE_KEY } from './keys';
import { productPricingReducer } from './reducers/product-pricing.reducer';
import { ProductPricingEffects } from './effects/product-pricing.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PRODUCT_PRICING_FEATURE_KEY, productPricingReducer), EffectsModule.forFeature([ProductPricingEffects])],
})
export class ProductPricingStoreModule {}
