import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCT_PRICING_FEATURE_KEY } from '../keys';
import { ProductPricingState } from '../reducers/product-pricing.reducer';

const selectState = createFeatureSelector<ProductPricingState>(PRODUCT_PRICING_FEATURE_KEY);
const selectItems = createSelector(selectState, (state) => state.items);

const selectItemUnits = (id: string, no: string) =>
    createSelector(selectState, (state) => state.items.find((item) => item.id === id && item.no === no)?.units ?? []);

const selectItemUnitsSelectOptions = (id: string, no: string) =>
    createSelector(selectItemUnits(id, no), (units) =>
        units.map((unit) => ({
            name: `${unit.code} (${Math.floor(unit.qty * 1000) / 1000})`,
            value: unit.code,
        })),
    );

const selectItemUnitQty = (id: string, no: string, code: string) =>
    createSelector(selectItemUnits(id, no), (units) => units.find((unit) => unit.code === code)?.qty ?? 1);

const selectItemLoading = (id: string, no: string) =>
    createSelector(selectState, (state) => state.items.find((item) => item.id === id && item.no === no)?.loading ?? false);

const selectLoadingAnything = createSelector(selectState, (state) => state.items.findIndex((item) => item.loading === true) !== -1);

export { selectItems, selectItemUnits, selectItemUnitsSelectOptions, selectItemUnitQty, selectItemLoading, selectLoadingAnything };
