import { createReducer, on } from '@ngrx/store';
import { productPricingActions } from '../actions/product-pricing.actions';
import { PricingUnit } from '../models/pricing.model';

export interface ProductPricingState {
    items: {
        id: string;
        no: string;
        units: PricingUnit[];
        loading: boolean;
    }[];
}

export const initialState: ProductPricingState = {
    items: [],
};

export const productPricingReducer = createReducer(
    initialState,

    on(productPricingActions.setProductPricings, (state, { product }): ProductPricingState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.id === product.id && item.no === product.no);

        // create new product pricing entry
        if (itemIndex === -1) {
            items.push({
                id: product.id,
                no: product.no,
                units: [new PricingUnit(product.defaultUnit)],
                loading: false,
            });

            return { ...state, items };
        }

        const unitIndex = items[itemIndex].units.findIndex((unit) => unit.code === product.defaultUnit);

        // create new unit for existing pricing entry
        if (unitIndex === -1) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                units: [...items[itemIndex].units, new PricingUnit(product.defaultUnit)],
            });

            return { ...state, items };
        }

        // replace existing unit for exisitng pricing entry
        const itemUnits = [...items[itemIndex].units];
        itemUnits.splice(unitIndex, 1, new PricingUnit(product.defaultUnit));

        items.splice(itemIndex, 1, {
            ...items[itemIndex],
            units: itemUnits,
        });

        return { ...state, items };
    }),

    on(productPricingActions.getProductPricings, (state, action): ProductPricingState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.id === action.id && item.no === action.no);

        // create new product pricing entry
        if (itemIndex === -1) {
            items.push({
                id: action.id,
                no: action.no,
                units: [],
                loading: false,
            });

            return { ...state, items };
        }
        return state;
    }),

    on(productPricingActions.getProductPricings, (state, action): ProductPricingState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.id === action.id && item.no === action.no);

        // set Loading
        if (itemIndex !== -1) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                loading: true,
            });

            return { ...state, items };
        }
        return state;
    }),

    on(productPricingActions.getProductPricingsSuccess, (state, action): ProductPricingState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.id === action.id && item.no === action.no);
        let units = [...items[itemIndex].units];

        action.items.forEach((item) => {
            const unitIndex = units.findIndex((unit) => unit.code === item.code);
            if (unitIndex === -1) {
                units = [...units, new PricingUnit(item.code, item.qtyPerUnit)];
            } else {
                units.splice(unitIndex, 1, new PricingUnit(item.code, item.qtyPerUnit));
            }
        });

        items.splice(itemIndex, 1, {
            ...items[itemIndex],
            units: units,
            loading: false,
        });

        return { ...state, items };
    }),

    on(productPricingActions.getProductPricingsError, (state, action): ProductPricingState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.id === action.id && item.no === action.no);

        items.splice(itemIndex, 1, {
            ...items[itemIndex],
            loading: false,
        });

        return { ...state, items };
    }),

    on(
        productPricingActions.clear,
        (): ProductPricingState => ({
            ...initialState,
        }),
    ),
);
