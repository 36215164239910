import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductDiscountsState } from '../reducers/product-discounts.reducer';
import { PRODUCT_DISCOUNTS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ProductDiscountsState>(PRODUCT_DISCOUNTS_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state) => state.loaded);
const selectProductDiscounts = createSelector(selectState, (state) => state.discountData?.productDiscounts);
const selectOldPrice = createSelector(selectState, (state) => state.discountData?.oldPrice);

export { selectLoaded, selectProductDiscounts, selectOldPrice };
