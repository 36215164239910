import { Product } from '@app/store/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const productSubstitutesActions = createActionGroup({
    source: 'Product Substitutes',
    events: {
        'Get Products': props<{ no: string }>(),
        'Get Products Callback': props<{ no: string }>(),
        'Get Products Success': props<{ no: string; items: Product[] }>(),
        'Get Products Error': props<{ no: string }>(),

        Clear: emptyProps(),
    },
});
