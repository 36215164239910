import { Injectable, inject } from '@angular/core';
import { mergeMap, from, map, catchError, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { productsActions } from '../../products/actions/products.actions';
import { productPricingActions } from '../actions/product-pricing.actions';
import { favouritesActions } from '../../favourites/actions/favourites.actions';
import { HttpService } from '../services/http.service';
import { productSubstitutesActions } from '../../product-substitutes/actions/product-substitutes.actions';
import { productActions } from '@app/store/product/actions/product.actions';
import { shoppingListItemsActions } from '@app/store/shopping-list-items/actions/shopping-list-items.actions';

@Injectable()
export class ProductPricingEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    productDetailsPricingsTrigger$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productActions.getProductDetailsSuccess),
            map(({ product }) => productPricingActions.setProductPricings({ product })),
        );
    });

    setProductPricingsTriggers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                productsActions.getProductsParamsSuccess,
                favouritesActions.getProductsSuccess,
                productSubstitutesActions.getProductsSuccess,
                shoppingListItemsActions.getShoppingListItemsSuccess,
            ),
            mergeMap(({ items }) => from(items.map((item) => productPricingActions.setProductPricings({ product: item })))),
        );
    });

    getProductPricingsCallback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productPricingActions.getProductPricings),
            mergeMap(({ id, no }) =>
                this.httpService.getProductPricing(id).pipe(
                    map((items) => productPricingActions.getProductPricingsSuccess({ id, no, items })),
                    catchError(() => of(productPricingActions.getProductPricingsError({ id, no }))),
                ),
            ),
        );
    });
}
