import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DiscountData } from '../models';

export const productDiscountsActions = createActionGroup({
    source: 'Product Discounts',
    events: {
        'Get Product Discounts': props<{ productId: string; productUnit: string }>(),
        'Get Product Discounts Success': props<{ discountData: DiscountData }>(),
        'Get Product Discounts Error': emptyProps(),

        Clear: emptyProps(),
    },
});
