import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductPricingFacade } from '../facades/product-pricing.facade';

export function productsPricingCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productPricingFacade = inject(ProductPricingFacade);
        productPricingFacade.clear();
        return true;
    };
}
