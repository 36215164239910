import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { productPricingActions } from '../actions/product-pricing.actions';
import { selectItemUnits, selectItemUnitsSelectOptions, selectItemUnitQty, selectLoadingAnything } from '../selectors/product-pricing.selector';

@Injectable({
    providedIn: 'root',
})
export class ProductPricingFacade {
    private store = inject(Store);

    loadingAnything$ = this.store.select(selectLoadingAnything);

    units$ = (id: string, no: string) => this.store.select(selectItemUnits(id, no));
    unitsSelectOptions$ = (id: string, no: string) => this.store.select(selectItemUnitsSelectOptions(id, no));
    unitQty$ = (id: string, no: string, code: string) => this.store.select(selectItemUnitQty(id, no, code));

    getProductUnits(id: string, no: string) {
        this.store.dispatch(productPricingActions.getProductPricings({ id, no }));
    }

    clear(): void {
        this.store.dispatch(productPricingActions.clear());
    }
}
