import { Pipe, PipeTransform, inject } from '@angular/core';
import { ShoppingCartFacade } from '@app/store/shopping-cart';
import { Observable } from 'rxjs';

@Pipe({
    name: 'cartItemProcessing',
})
export class CartItemProcessingPipe implements PipeTransform {
    private cartFacade = inject(ShoppingCartFacade);

    transform(id: string, no: string): Observable<boolean> {
        return this.cartFacade.cartItemProcessing$(id, no);
    }
}
