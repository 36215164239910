import { ProductDiscount } from './product-discount';
import { ProductDiscountDto } from './product-discount.dto';

export class DiscountData {
    productDiscounts: ProductDiscount[];
    oldPrice?: number;

    constructor(productDiscounts: ProductDiscountDto[]) {
        this.productDiscounts = productDiscounts
            .map((dto) => new ProductDiscount(dto))
            .sort((a, b) => a.minimumQuantity - b.minimumQuantity)
            .filter((discount) => discount.price > 0);

        this.productDiscounts.forEach((discount) => {
            this.calculateDiscountPercentage(discount);
        });

        const regularProduct = productDiscounts.find((discount) => discount.minimum_quantity_for_price_promo === 1);

        if (regularProduct) {
            this.oldPrice = Math.round(regularProduct.item_old_price * 100);
        }
    }

    private calculateDiscountPercentage(discount: ProductDiscount) {
        if (discount.minimumQuantity === 1) {
            return;
        }

        const regularPrice = this.productDiscounts.find((discount) => discount.minimumQuantity === 1)?.price;

        if (!regularPrice) {
            return;
        }

        const discountPercentage = (1 - discount.price / regularPrice) * 100;

        if (discountPercentage > 0) {
            discount.discountPercentage = Number(discountPercentage.toFixed(2));
        }
    }
}
