import { createReducer, on } from '@ngrx/store';
import { productDiscountsActions } from '../actions/product-discounts.actions';
import { DiscountData } from '../models';

export interface ProductDiscountsState {
    loaded: boolean | null;
    discountData: DiscountData | null;
}

export const initialState: ProductDiscountsState = {
    loaded: null,
    discountData: null,
};

export const productDiscountsReducer = createReducer(
    initialState,
    on(
        productDiscountsActions.getProductDiscountsSuccess,
        (state, payload): ProductDiscountsState => ({
            ...state,
            loaded: true,
            discountData: payload.discountData,
        }),
    ),
    on(
        productDiscountsActions.getProductDiscountsError,
        (state): ProductDiscountsState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        productDiscountsActions.clear,
        (): ProductDiscountsState => ({
            ...initialState,
        }),
    ),
);
