import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import { productDiscountsActions } from '../actions/product-discounts.actions';
import { LoadersFacade } from '@app/store/loader';

@Injectable()
export class ProductDiscountsEffects {
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getProductDiscounts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productDiscountsActions.getProductDiscounts),
            switchMap(({ productId, productUnit }) => {
                this.loadersFacade.add('get-product-discounts');

                return this.httpService.getProductDiscounts(productId, productUnit).pipe(
                    map((discountData) => productDiscountsActions.getProductDiscountsSuccess({ discountData })),
                    catchError(() => of(productDiscountsActions.getProductDiscountsError())),
                    finalize(() => this.loadersFacade.delete('get-product-discounts')),
                );
            }),
        );
    });
}
