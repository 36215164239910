import { ProductPricingDto } from './product-pricing.dto';

export class ProductPricing {
    code: string;
    qtyPerUnit: number;

    constructor(productPricingDto: ProductPricingDto) {
        this.code = productPricingDto.code;
        this.qtyPerUnit = productPricingDto.qty_per_unit_of_measure;
    }
}
